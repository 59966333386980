html, body {
  background-color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #222;
}
.flex-1 {
  flex: 1;
}
.header {
  padding: 40px 0;
  border-bottom: 2px solid #2081BC;
}
.header-content {
  display: flex;
  width: 1000px;
  margin: 0 auto;
  align-items: center;
}
.header-content h1 {
  text-align: right;
  margin: 0;
}
.header-content a {
  color: inherit;
  text-decoration: none;
}
.logo {
  height: 60px;
}
.content {
  width: 1000px;
  margin: 40px auto 200px auto;
  min-height: 50vh;
}
footer {
  border-top: 1px solid #ddd;
  width: 1000px;
  margin: 40px auto;
  padding-top: 20px;
  font-size: 12px;
}

.icon-row {
  display: flex;
  align-items: center;
  margin: 14px;
  line-height: 1;
}
.icon-row img {
  margin-right: 6px;
}